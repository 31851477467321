<template>
  <ul class="brand-list">
    <li :key="`brand_${i}_${f.feedId}`" v-for="(f, i) in brandItems" class="brand-list-item">
      <BrandListItem :model="f" @contentClick="contentClicked(f, i)"></BrandListItem>
    </li>
  </ul>
</template>

<script>
import BrandListItem from '@/components/brand/BrandListItem/BrandListItem.vue';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'BrandList',
  mixins: [gtmUtils],
  components: {
    BrandListItem,
  },
  props: {
    brandItems: {
      type: Array,
    },
  },
  data() {
    return {};
  },
  methods: {
    contentClicked(feed, index) {
      this.pushDataLayerEvent('contentClicked', {
        ...feed,
        pageName: this.$route.pageTitle || '',
        index: index + 1,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.brand-list {
  list-style: none;
  padding: 0;
  margin: 0;
  li {
    padding: 0 0 15px 0;
    float: left;
    width: 48%;
    margin-right: 2%;
    height: 180px;
    background-color: palette-color-level('white', '100');
    border-radius: palette-radius-level('2');
    margin-bottom: palette-space-level('10');
    overflow: hidden;
    &:nth-child(2n) {
      margin-right: 0;
      float: right;
    }
  }
}
</style>
