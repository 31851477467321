<template>
  <div class="brand-item-container">
    <BrandMediaContentBox
      class="brand-media"
      :mediaSrc="modelData.imageURL"
      :alt="modelData.title"
      allowfullscreen
      @click.native="detailClick(modelData.feedType, modelData.surveyType)"
      :aspectRatios="propData.media.aspect.by_16_9"
      :as="propData.media.type.image"
    >
    </BrandMediaContentBox>
    <div class="brand-item-summary">
      <VueHeadline level="2">{{ modelData.title }}</VueHeadline>
    </div>
  </div>
</template>
<script>
import MEDIA_CONSTANTS from '@/constants/media.constants.js';
import VueHeadline from '@/components/shared/VueHeadline/VueHeadline.vue';
import BrandMediaContentBox from '@/components/brand/BrandMediaContentBox/BrandMediaContentBox.vue';
import RoutesSecure from '@/router/routes/RoutesSecure';
import RoutesSurvey from '@/router/routes/secure/RoutesSurvey';
import RoutesPlayAndWin from '@/router/routes/secure/RoutesPlayAndWin';

export default {
  name: 'BrandListItem',
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  components: {
    VueHeadline,
    BrandMediaContentBox,
  },
  data() {
    return {
      modelData: Object.assign({}, this.model),
    };
  },
  computed: {
    propData() {
      const media = {
        aspect: MEDIA_CONSTANTS.MEDIA_ASPECT_RATIOS,
        type: MEDIA_CONSTANTS.MEDIA_CONTENT_TYPES,
      };
      return { media };
    },
  },
  methods: {
    detailClick(feedType, surveyType) {
      if (feedType === 1) {
        this.$router.push({
          name: `${RoutesSecure.FeedDetail.name}`,
          params: { id: this.modelData.feedId },
        });
      } else if (feedType === 2) {
        if (surveyType === 2 || surveyType === 1) {
          this.$router.push({
            name: `${RoutesSurvey.TakeSurvey.name}`,
            params: { id: this.modelData.feedId },
          });
        }
        if (surveyType === 3) {
          this.$router.push({
            name: `${RoutesPlayAndWin.PlayAndWinDetail.name}`,
          });
        }
      } else {
        const errModalConfig = {
          isModalOpened: true,
          modalText: 'Post bulunamadı!',
          modalType: 'alert',
          alertType: 'error',
          firstButtonText: 'Tamam',
        };
        this.$store.dispatch('app/setModalConfig', errModalConfig);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.brand-media {
  position: relative;
  display: flex;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  overflow: hidden;
  &-content {
    position: absolute;
    bottom: 10px;
    display: flex;
    justify-content: space-between;
    left: 0;
    right: 0;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
  }

  &:before {
    content: '';
    display: block;
  }

  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.brand-item-container {
  margin-bottom: 15px;
  .brand-item-summary {
    padding: 10px 0 0 10px;
    h2 {
      padding-bottom: 10px;
      font-size: palette-font-size-level('4');
      text-align: left;
    }

    .brand-item-content {
      color: $brand-link-primary-hover-color;
      margin-bottom: 0;
    }
  }
}
</style>
